// Show from data in state.

import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import upperFirst from 'lodash/upperFirst';
import map from 'lodash/map';

import { setDays, deleteAllPetsList, deleteCheckoutState } from '../utils';
import icn_insta from '../assets/img_og_insta.png';
import icn_fb from '../assets/img_og_fb.png';
import ButtonLoader from '../components/common/ButtonLoader';
import BareLayout from '../layouts/bareLayout';
import { Order } from '../services/models';
import { getOrder } from 'services/orders.api';
import { useAppSelector } from 'state';
import { fetchCurrentBasket, useAppDispatch } from 'state';
import CardWrapper from '../components/Home/CardWrapper';
import { Footer } from '../components/Footer';

export default ({ location }: { location: any }) => {
  const dispatch = useAppDispatch();

  const { order } = location.state || {};

  const [discount, setDiscount] = useState(0);
  const [orderDataToDisplay, setOrderDataToDisplay] = useState(
    (order || {}) as Order,
  );
  const [orderSummary, setOrderSummary] = useState({});
  const [dogNames, setDogNames] = useState('');
  const [loading, setLoading] = useState(location.state === null);

  const oneOff = !dogNames;
  const params = new URLSearchParams(location.search);
  const productsList = useAppSelector(state => state.products.entities);
  useEffect(() => {
    () => {
      document.body.style.overflow = 'auto';
    }
  }, [])
  useEffect(() => {
    // Registering a user will create a new cart. Fetch that new cart here
    //  so Solve knows the correct basket ID for the order success page.
    dispatch(fetchCurrentBasket());

      (async () => {
        const orderId = params.get('orderId');
        const orderResp = await getOrder(orderId);
        const orderData = orderResp.results[0];
        setOrderDataToDisplay(orderData);
        const allPetNames = [] as string[];
        let petNameString = '';
        const orderSummaryToDisplay = {} as any;

        orderData.order_summary.forEach((item, index) => {
          if (allPetNames.indexOf(item.pet_name) === -1) {
            allPetNames.push(item.pet_name);
          }
          const existingArr =
            orderSummaryToDisplay[item.pet_name] &&
            orderSummaryToDisplay[item.pet_name].products
              ? orderSummaryToDisplay[item.pet_name].products
              : [];
          const toPush = {
            name: item.product,
            frequency: item.frequency,
            quantity: item.quantity,
            price: item.price.price_incl_tax_excl_discounts,
            isMain: item.product.includes('Chicken Dog Box') || item.product.includes('Dog Food - Luxe Lamb'),
            isAddOn: item.add_on,
            isOneOff: item.one_off
          };
          existingArr.push(toPush);
          if (orderData.furbaby_name?.length) {
            orderSummaryToDisplay[item.pet_name] = {
              name: item.pet_name,
              products: existingArr,
            };
          } else {
            orderSummaryToDisplay[index] = {
              products: existingArr,
            };
          }
        });
        setOrderSummary(orderSummaryToDisplay);
        allPetNames.forEach(item => {
          petNameString = petNameString
            ? `${petNameString}, ${upperFirst(item)}`
            : upperFirst(item);
        });
        setDogNames(petNameString);
        setLoading(false);
      })();
  }, []);

  function waitUntil() {
    setLoading(true);
    return new Promise(resolve => {
      const interval = setInterval(() => {
        resolve(() => {});
        setLoading(false);
        clearInterval(interval);
      }, 3000);
    });
  }
  return (
    <>
      <BareLayout>
        <div className="order-success-wrapper">
          <div className="how-wrapper">
            <div className="container-fluid">
              <div className="how-hdr how-hdr-orders">
                <div className="def-w-max def-w-790">
                  {!loading && (
                    <h2 className="mgn-bot-30 text-center">
                      Woof!
                      <br />
                      {oneOff && (
                        <>
                          <span className="txt-capitalize">Your </span>
                        </>
                      )}
                      {!oneOff && (
                        <>
                          <span className="txt-capitalize">{dogNames}</span>’s
                        </>
                      )}{' '}
                      delivery is on its way.
                    </h2>
                  )}
                  <p className="text-center">
                    You will receive a confirmation email in your inbox shortly.
                    <br />
                    Going forward, you can manage your furbabies and their
                    orders from your dashboard.
                  </p>
                  <div className="text-center">
                    <ButtonLoader
                      className="btn-def mgn-top-30"
                      text="Visit my Dashboard"
                      onClick={() => {
                        waitUntil().then(() => {
                          deleteAllPetsList();
                          deleteCheckoutState();
                          window.location.href = '/account/dashboard/'
                        });
                      }}
                      loading={loading}
                      disabled={loading}
                    />
                  </div>
                  {!loading && (
                    <div className="ord-cards-wrapper mgn-top-50">
                      <div className="fmf-card">
                        <div className="fmf-card-hdr clearfix">
                          <div className="float-left">
                            <span>Delivery Information</span>
                          </div>
                        </div>
                        <div className="fmf-card-body">
                          <div className="my-fur-wrapper order-card-wrapper">
                            <div className="my-fur-item">
                              <div className="row">
                                <div className="col-12 col-sm-3">
                                  <div className="txt-wrapper">
                                    <div className="name">Furparent</div>
                                    <div className="dets">
                                      <span className="txt-capitalize">
                                        {orderDataToDisplay?.purchased_by}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-5">
                                  <div className="txt-wrapper">
                                    <div className="name">Delivery Address</div>
                                    <div className="dets">
                                      <span>
                                        {
                                          orderDataToDisplay.shipping_address
                                            ?.search_text
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                  <div className="txt-wrapper">
                                    <div className="name">
                                      Expected Delivery
                                    </div>
                                    <div className="dets">
                                      <span>{setDays(4)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {oneOff &&
                              orderDataToDisplay.furbaby_name &&
                              orderDataToDisplay.furbaby_name.length === 0 &&
                              orderDataToDisplay.frequency &&
                              orderDataToDisplay.frequency[0] ? (
                                <div className="row mgn-top-10">
                                  <div className="col-12">
                                    <span className="toggle-icon left" />
                                    <span className="mgn-lft-10">
                                      {'This order will repeat every ' +
                                        orderDataToDisplay.frequency[0] +
                                        ' days. Change or cancel any time.'}
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fmf-card">
                        <div className="fmf-card-hdr clearfix">
                          <div className="float-left">
                            <span>Order Summary</span>
                          </div>
                        </div>
                        <div className="fmf-card-body">
                          <div className="my-fur-wrapper order-card-wrapper">
                            <div className="my-fur-item">
                              <div className="prod-desc">
                                {!oneOff &&
                                  map(orderSummary, (item, petName) => (
                                    <div key={petName}>
                                      {item.products?.map(productItem => (
                                        <>
                                          {productItem.isMain && !productItem.isAddOn ? (
                                            <div
                                              className="prod-desc-item"
                                              key={'pet' + petName}
                                            >
                                              <div className="row">
                                                <div className="col-sm-8">
                                                  <div className="col-desc-lt">
                                                    {productItem.isMain && (
                                                      <div className="line1">
                                                        <span className="txt-capitalize">
                                                          {petName}
                                                        </span>
                                                        ’s {productItem.name}
                                                      </div>
                                                    )}
                                                    <div className="line2">
                                                      {productItem.quantity}x{' '}
                                                      {productItem.name}{' '}
                                                      delivered every{' '}
                                                      {productItem.frequency}{' '}
                                                      days
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4">
                                                  <div className="col-desc-rt flex-start">
                                                    <div className="txt">
                                                      ${productItem.price}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="prod-desc-item"
                                              key={'pet' + petName}
                                            >
                                              <div className="row">
                                                <div className="col-sm-8">
                                                  <div className="col-desc-lt">
                                                    <div className="line2">
                                                      {`${productItem.quantity}x ${productItem.name}`}{' '}
                                                      <br />
                                                      {`Every delivery`}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4">
                                                  <div className="col-desc-rt flex-start">
                                                    <div className="txt">
                                                      ${productItem.price}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  ))}
                                {oneOff &&
                                  map(orderSummary, (item, index) => (
                                    <div key={index}>
                                      {item.products?.map(productItem => (
                                        <div
                                          className="prod-desc-item"
                                          key={'line' + index}
                                        >
                                          <div className="row">
                                            <div className="col-sm-8">
                                              <div className="col-desc-lt">
                                                <div className="line2">
                                                  {`${productItem.quantity}x ${productItem.name}`}{' '}
                                                  <br />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="col-desc-rt flex-start">
                                                <div className="txt">
                                                  ${productItem.price}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                {discount > 0 && (
                                  <div className="disc-bar-wrapper">
                                    <div className="content-wrapper">
                                      <div className="content">
                                        {discount}% off your total order
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="prod-desc-item row mgn-top-30">
                                  <div className="col-sm-8">
                                    <div className="col-desc-lt">
                                      <div className="line1">Total</div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="col-desc-rt flex-start">
                                      {Number(
                                        orderDataToDisplay.total_incl_tax_excl_shipping_charge,
                                      ) ===
                                      Number(
                                        orderDataToDisplay.total_incl_tax_excl_discounts,
                                      ) ? (
                                        <div className="txt">
                                          $
                                          {
                                            orderDataToDisplay.total_incl_tax_excl_shipping_charge
                                          }
                                        </div>
                                      ) : (
                                        <div className="txt">
                                          $
                                          {
                                            orderDataToDisplay.total_incl_tax_excl_shipping_charge
                                          }{' '}
                                          <span className="txt-fnt-lt txt-strike">
                                            $
                                            {
                                              orderDataToDisplay.total_incl_tax_excl_discounts
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="prod-desc-item">
                                  <div className="row">
                                    <div className="col-sm-8">
                                      <div className="col-desc-lt">
                                        <div className="line1">Shipping</div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="col-desc-rt flex-start">
                                        <div className="txt">
                                          {orderDataToDisplay.base_shipping_charge <=
                                          0
                                            ? 'FREE'
                                            : '$' +
                                              orderDataToDisplay.base_shipping_charge}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Number(orderDataToDisplay.shipping_incl_tax) -
                                  orderDataToDisplay.base_shipping_charge >
                                0 ? (
                                  <div className="prod-desc-item">
                                    <div className="row">
                                      <div className="col-sm-8">
                                        <div className="col-desc-lt">
                                          <div className="line1">
                                            Rural delivery
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="col-desc-rt flex-start">
                                          <div className="txt">
                                            {'$' +
                                              (
                                                Number(
                                                  orderDataToDisplay.shipping_incl_tax,
                                                ) -
                                                orderDataToDisplay.base_shipping_charge
                                              ).toFixed(2)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="prod-desc-item">
                                  <div className="row">
                                    <div className="col-sm-8">
                                      <div className="col-desc-lt">
                                        <div className="line1">
                                          Delivery Total
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="col-desc-rt flex-start">
                                        <div className="txt">
                                          ${orderDataToDisplay.total_incl_tax}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-wrapper">
            <div className="container-fluid">
              <div className="def-w-max">
                <h2 className="text-center sc-lg">Join our Furfamily!</h2>
                <p className="text-center mgn-top-20">
                  Share a photo of your Furbaby with{' '}
                  <strong>#feedmyfurbaby</strong> for a chance to win your next
                  box.
                </p>
                <div className="text-center ord-img0-wrapper">
                  <a
                    href="https://www.instagram.com/myfurbabynz/"
                    target="_blank"
                  >
                    <img
                      src={icn_insta}
                      alt="twitter-icon"
                      title="twitter-icon"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/myfurbabynz"
                    target="_blank"
                  >
                    <img className="ft-img-fb" src={icn_fb} alt="fb-icon" />
                  </a>
                </div>
              </div>
              <CardWrapper/>
            </div>
          </div>
        </div>
      </BareLayout>
      <Footer />
    </>
  );
};
